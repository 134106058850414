import React, { Component } from "react"
import {graphql } from "gatsby"
import Layout from "../layout/layout"
import Latest from '../components/latest_posts/index'
// import Slider from '../components/slider2/slider'
import ContactForm from '../components/contactForm/contactSection'
import Seo from '../components/seo/'
import Headlines from '../components/headlines/headlines'
import ServiceCard from '../components/service-cards/index'
import ImageTextList from '../components/ImageText/imageTextList'
import CatPosts from '../components/catPost/index';
import Landing from '../components/landing/landing';

class Homepage extends Component {
  render() {
    // const page = this.props.data.wordpressPage

    return (
      <Layout>
        <Seo postTitle="Home " />
        {/* <Slider/> */}
        <Landing/>
        <Latest/>
        <Headlines headlines="React" subheadlines="Debugging React Components"/>
        <CatPosts/>
        {/* <ImageTextList/> */}
        <Headlines headlines="Graphql" subheadlines="Quering data from the database"/>
        <ServiceCard/>
        <Headlines headlines="Gatsby" subheadlines="Using it to build static sites"/>
       <ContactForm/>
     
      </Layout>
    )
  }
}

export default Homepage

export const pageQuery = graphql`
  query {
    wordpressPage(id: {eq: "cG9zdDo0MDI2"}) {
      content
      id
      title
    }
  }
`
