import React from "react"
import { StaticQuery, graphql } from "gatsby"
import ServiceCards from './service-card.js'
// import "./style.scss"
// import Img from "gatsby-image"

const RecentData = () => (
  <StaticQuery
    query={graphql`
    {
      allWordpressService(
        limit: 3
        filter: {services_card: {indexPageDisplay: {eq: true}}}
        sort: {fields: date, order: DESC}
      ) {
        edges {
          node {
            services_card {
              servicesCardImage {
                localFile {
                  childImageSharp {
                    fluid {
                      srcSet
                    }
                  }
                }
              }
              secondHeadline
              indexPageDisplay
              title
            }
          }
        }
      }
    }
    `}
    render={data => <ServiceCards data={data.allWordpressService.edges} />}
  ></StaticQuery>
)

export default RecentData
