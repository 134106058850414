import React from "react";
// import { Link } from "gatsby"
import "./style.scss";

export default function Recent(props) {
  return (
      <div className="ServiceCard"> 
        <h1>Skills</h1>
        <div>
        {/* {console.log("Services")}
        {console.log(props)} */}
        {props.data.filter(({node}) => {
        
          if(node.services_card.indexPageDisplay == null){
            return false
          }else{
            return true;
          }
        }).map(({ node }) => (
            <div>
                 { (node.services_card.servicesCardImage != null)
            ? <picture className='service_picture'> <img alt="" srcSet={node.services_card.servicesCardImage.localFile.childImageSharp.fluid.srcSet}/> </picture>
            :  <picture className='service_picture'> <img alt="" srcSet="https://via.placeholder.com/300x200"/> </picture> 
            }
            
            <h2>{node.services_card.title}</h2>
            {/* <p className="" >{node.services_card.secondHeadline}</p> */}
              </div>
            ))}     
          </div>
      </div>
  )
}