import React from "react";
import { StaticQuery, graphql } from "gatsby";
import CatPosts from './catPosts';
import "./style.scss";
// import Img from "gatsby-image"

const RecentData = () => (
  <StaticQuery
    query={graphql`
    {
      allWordpressPost(
        limit: 4
        filter: {categories: {nodes: {elemMatch: {id: {eq: "dGVybTo5"}}}}}
        sort: {fields: date, order: DESC}
      ) {
        edges {
          node {
            id
            title
            slug
            excerpt
            images {
              squareFeaturedImage {
                localFile {
                  childImageSharp {
                    fixed {
                      srcSet
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    
    `}
    render={data => <CatPosts data={data.allWordpressPost.edges} />}
  ></StaticQuery>
)

export default RecentData
