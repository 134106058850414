import React from "react"
// import { Link } from "gatsby"
import "./style.scss"

export default function Recent(props) {
  return (
      <div className="headlines"> 
        <div>
          <h1>{props.headlines}</h1>
          <h2>{props.subheadlines}</h2>
        </div>
      </div>
  )
}