import React from "react"
import { Link } from "gatsby"
import "./style.scss"

export default function CatPosts(props) {
  return (
      <div className="latest"> 
        <h1>Latest CSS Posts</h1>
        <div>
        {/* {console.log("latest posts:")}
        {console.log(props)} */}
          {props.data.filter(({node}) => {
           if(node.images.squareFeaturedImage){
              return true;
           }
           else{
             return false
           }
            
        }).map(({ node }) => (
            <div>

            <Link to={"/blog/" + node.slug}> 
              <picture className='latest_picture'> <img alt="" srcSet={node.images.squareFeaturedImage.localFile.childImageSharp.fixed.srcSet}/> </picture>    
            </Link>

            <h2>{node.title}</h2>
            {/* <p className="" dangerouslySetInnerHTML={{ __html: node.excerpt}} /> */}
            
              </div>
            ))}     
          </div>
      </div>
  )
}